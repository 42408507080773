/* ------------------------------------------------------------ *\
	Section Background
\* ------------------------------------------------------------ */

.section-background {
	padding: 23.2rem 0 33.8rem;
	position: relative;
	color: $c-white; 

	@include breakpoint-down(mobile) {
		padding: 0; 
	}

	.section__image {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;

		&:after {
			content: "";
			position: absolute;
			top: 0; 
			left: 0;
			right: 0; 
			bottom: 0; 
			background-color: rgba(0, 0, 0, .5); 
		}
	}

	.section__image img {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;
		object-fit: cover;
	}

	.section__pattern {
		opacity: .6;
		mix-blend-mode: multiply;
	}

	.shell {
		position: relative;

		@include breakpoint-down(mobile) {
			padding: 5.7rem $shell-gutter-mobile 16.9rem; 
		}
	}

	.section__head {
		position: absolute;
		top: -17rem; 
		right: $shell-gutter;
		padding-right: 1rem;
		color: $c-yellow;
		border-bottom: .2rem solid currentColor; 
		transform: translate3d(0, 100px, 0) rotateZ(-90deg);
		transform-origin: bottom right;

		&.aos-animate{
			transform: translate3d(0, 0, 0) rotateZ(-90deg);
		}

		@include breakpoint-down(mobile) {
			top: auto;
			bottom: 19.8rem;
			right: 1.5rem;
			transform-origin: bottom right;
			transform: translate(0, 0) rotateZ(-90deg);
			padding-right: 0;
		}
	}

	.section__head h1 {
		font-family: $ff-garamond; 
		font-weight: 500; 
		line-height: 1.1; 
		margin-right: -1.6rem;
		margin-left: -1.8rem;

		@include breakpoint-down(mobile) {
			font-size: 10rem;
			line-height: .95;
			margin: 0 -2rem 0 0; 
		}
	}

	.section__content {
		padding-left: 10.6rem;
		max-width: 46%; 

		@include breakpoint-down(small-desktop) {
			max-width: 58%;
		}

		@include breakpoint-down(tablet) {
			padding-left: 0;
		}

		@include breakpoint-down(mobile) {
			max-width: unset;
		}
	}

	.section__content h3 {
		margin-bottom: 1.2rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			margin-bottom: 1.9rem;
		}
	}

	.section__content p:not(:last-child) {
		margin-bottom: 1.4rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 3.2rem;
		}
	}

	.section__content p {
		max-width: 40.8rem;
	}

	.section__content .btn {
		@include breakpoint-down(mobile) {
			padding: 1.9rem 9.4rem;
		}
	}
}