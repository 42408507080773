/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

.subscribe {
	padding-bottom: .4rem;
	border-bottom: .1rem solid rgba($c-gray-4, .2); 

	@include breakpoint-down(mobile) {
		padding-bottom: 0;
	}

	form {
		display: flex;
		align-items: flex-start;
	}

	.subscribe__field {
		flex: 0 1 100%;
		max-width: 100%;
		width: 100%;
		background-color: transparent; 
		color: $c-white; 
		border-radius: 0;
		border: 0; 
		font-size: 1.3rem;
		line-height: 1.2;
		padding: 1rem 3rem; 
		background-image: url('../images/temp/ico-mail@2x.png'); 
		background-size: 3rem 3rem;
		background-position: center left;
		background-repeat: no-repeat; 
		color: $c-gray-4;

		@include breakpoint-down(mobile) {
			padding: 1rem 2.8rem;
			background-size: 2.4rem 2.4rem;
		}
	}

	.subscribe__btn {
		flex: 1 0 auto;
		font-size: 1.4rem;
		font-weight: 600;
		line-height: 1.2;
		padding: 0.7rem 1.6rem;
		border-radius: 0;
		border: 0; 
		color: $c-black-off;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		background-color: $c-white;


		@include breakpoint-down(mobile) {
			padding: .6rem 1.2rem;
		}
	}
}