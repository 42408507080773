/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	display: flex;
	align-items: center;

	.ico-facebook, .ico-instagram{
		svg{
			color: $c-yellow;
		}
	}

	@include breakpoint-down(mobile) {
		flex-direction: column;
		align-items: flex-start;
		padding: 2.6rem 0.5rem 4.9rem 1rem;
		border-left: .2rem solid $c-yellow; 
	}

	span {
		font-size: 2rem;
		line-height: 1.4; 
		font-weight: 700; 
		margin-right: .8rem; 

		@include breakpoint-down(mobile) {
			margin-right: 0; 
			font-size: 1.4rem;
			line-height: 2;
			font-weight: 500;
			margin-bottom: .7rem;
		}
	}

	ul {
		list-style: none;
		display: flex;
		align-items: center;
		font-size: 0;

		@include breakpoint-down(mobile) {
			flex-direction: column;
			align-items: flex-start; 
		}
	}

	li {
		@include breakpoint-down(mobile) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 2.8rem; 
			height: 2.8rem;
		}
	}

	a {
		font-size: 0;
		display: inline-block;
		transition: opacity $t-duration;
	}

	li:hover a {
		opacity: .5;
	}

	li + li {
		margin-left: 1.8rem;

		@include breakpoint-down(mobile) {
			margin-left: 0;
			margin-top: 2.1rem; 
		}
	}
}