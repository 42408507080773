#age-modal{
    .modal-dialog{
        max-width: 1160px;
        margin-top: 140px;

        @media only screen and (max-width: 1250px) {
            max-width: 580px;
        }
    }

    .modal-content{
        border: none !important;
    }

    .modal-body{
        display: flex;
        padding: 0;
        width: 100%;
        border: none !important;

        > div:nth-child(1){
            flex: 0 0 49.7%;

            img{
                width: 100%;
                height: 790px;
            }

            @media only screen and (max-width: 1250px) {
                display: none;
            }
        }

        .modal__info{
            text-align: center;
            background: #AA8B3F;
            padding: 112px 50px 0 50px;
            width: 100%;

            @media only screen and (max-width: 1250px) {
                padding: 50px 10px 30px 10px;
            }

            .modal__logo{
                width: 180px;
                margin-bottom: 43px;

                @media only screen and (max-width: 1250px) {
                    width: 140px;
                }
            }

            h3{
                font-size: 32px;
                text-transform: uppercase;
                margin-bottom: 37px;
                color: white;
                font-weight: bold;
            }

            p{
                color: white;
                font-size: 16px;
                padding: 0 40px;
                line-height: 29px;
                margin-bottom: 45px;

                a{
                    color: black;
                }
            }

            label{
                margin-bottom: 44px;
            }

            .checkbox__modal{
                @media only screen and (max-width: 1250px) {
                    padding: 0 50px;
                }

                @media only screen and (max-width: 560px) {
                    padding: 0 10px;
                }
            }
        }
    }
}

#alerts-modal{
    .modal-dialog{
        max-width: 1160px;
        margin-top: 140px;

        @media only screen and (max-width: 1250px) {
            max-width: 580px;
        }

        @media only screen and (max-width: 560px) {
            opacity: 0.8;
        }
    }

    .modal-content{
        border: none !important;
    }

    .modal-body{
        padding: 0;
        position: relative;
        height: 790px;
        background: #112F07;
        border: none !important;

        @media only screen and (max-width: 1250px) {
            height: fit-content;
        }

        > .background__image{
            width: 100%;
            height: auto;
            position: absolute;
            left: 0;
            top: 0;

            @media only screen and (max-width: 1250px) {
                display: none;
            }
        }

        > div{
            padding: 35px 100px;
            position: relative;

            @media only screen and (max-width: 560px) {
                padding: 15px 40px;
            }

            .header__image{
                width: 100%;
                margin-bottom: 66px;
            }

            h3{
                text-align: center;
                color: white;
                font-size: 49px;
                margin-bottom: 60px;

                @media only screen and (max-width: 1250px) {
                    font-size: 30px;
                }
            }

            .alerts{
                display: flex;
                margin: 0 -50px;

                @media only screen and (max-width: 1250px) {
                    flex-direction: column;
                    align-items: center;
                }

                .alert__item{
                    width: 33%;
                    text-align: center;
                    padding: 30px;

                    @media only screen and (max-width: 1250px) {
                        width: fit-content;
                    }

                    .date{
                        color: #AA8B3F;
                        font-size: 31px;
                        margin-bottom: 15px;
                    }

                    .title{
                        color: white;
                        font-size: 26px;
                        font-weight: bold;
                        margin-bottom: 10px;

                        @media only screen and (max-width: 1250px) {
                            font-size: 20px;
                        }
                    }

                    p{
                        color: white;
                        font-size: 18px;
                        line-height: 29px;

                        @media only screen and (max-width: 1250px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}