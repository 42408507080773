/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */

.accordion {
	.accordion__section + .accordion__section {
		margin-top: 6rem;

		@include breakpoint-down(mobile) {
			margin-top: 1.8rem;
		}
	}

	.accordion__head {
		background-color: $c-beige; 
		padding: 4rem calc((104rem - 81.6rem) / 2) 7.3rem;
		position: relative;
		max-width: 104rem;
		margin: 0 auto; 
		transition: max-width $t-duration, padding $t-duration;

		@include breakpoint-down(small-desktop) {
			max-width: min(calc(100vw - 6rem), 104rem);
			padding: 4rem max(calc((100vw - min(calc(100vw - 6rem), 104rem)) / 2), 10rem) 7.3rem;
		}

		@include breakpoint-down(tablet) {
			padding: 4rem max(calc((100% - 81.6rem) / 2), 3rem);
		}

		@include breakpoint-down(mobile) {
			max-width: calc(100vw - (#{$shell-gutter-mobile} * 2));
			padding: 4rem 2.5rem 5.5rem;
		}
	}

	.accordion__section.is-current .accordion__head {
		max-width: 100%;
		padding: 5.8rem calc((100vw - 83rem) / 2);

		@include breakpoint-down(tablet) {
			padding: 5.8rem max(calc((100vw - 81.6rem) / 2), 3rem);
		}

		@include breakpoint-down(mobile) {
			max-width: 100vw;
			padding: 3.8rem $shell-gutter-mobile 5.7rem;
		}
	}

	.accordion__head .accordion__actions {
		position: absolute;
		top: 5.7rem;
		right: calc((104rem - 81.6rem) / 2);
		margin-right: -5.4rem; 
		max-width: max-content;
		margin-left: auto;
		transition: margin $t-duration, top $t-duration, right $t-duration;

		@include breakpoint-down(small-desktop) {
			margin-right: -3rem;
		}

		@include breakpoint-down(tablet) {
			position: static;
			margin: 2rem 0 0 auto;
		}

		@include breakpoint-down(mobile) {
			margin: 3.7rem 0 0 auto;
			max-width: unset;
		}
	}

	.accordion__section.is-current .accordion__head .accordion__actions {
		top: 7.6rem; 
		right: calc((100vw - 83rem) / 2);
		margin-right: -9rem;

		@include breakpoint-down(small-desktop) {
			margin-right: -5rem;
		}

		@include breakpoint-down(tablet) {
			margin-right: 0;
		}

		@include breakpoint-down(mobile) {
			margin: 3.8rem 0 0; 
		}
	}

	.accordion__head .list-props {
		color: $c-gray-4;
		text-transform: uppercase;
		font-size: 1.3rem;
		font-weight: 700; 
		line-height: 1.23; 
		letter-spacing: 0.08em;
		margin-bottom: 0;
		padding-left: 0;
	}

	.accordion__head-bar {
		margin-bottom: 5.7rem;
		position: relative;

		@include breakpoint-down(mobile) {
			margin-bottom: 1.1rem;
		}
	}

	.accordion__section.is-current .accordion__head-bar {
		@include breakpoint-down(mobile) {
			margin-bottom: 2.5rem;
		}
	}

	.accordion__head-content {
		color: $c-gray-4;

		@include breakpoint-down(mobile) {
			line-height: 2.29;
			padding-right: 0.2rem;
		}
	}

	.accordion__head-content p {
		@include breakpoint-down(mobile) {
			max-height: 32.1rem;
			display: -webkit-box;
			-webkit-line-clamp: 10;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	.accordion__section.is-current .accordion__head-content {
		@include breakpoint-down(mobile) {
			line-height: 2;
		}
	}

	.accordion__section.is-current .accordion__head-content p {
		@include breakpoint-down(mobile) {
			display: block;
			-webkit-line-clamp: unset;
			-webkit-box-orient: vertical;
			max-height: unset;
		}
	}

	.accordion__head h4 {
		margin-bottom: 1.3rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 3.6rem;
			line-height: 1.56;
			transition: font-size $t-duration;
		}
	}

	.accordion__section.is-current .accordion__head h4 {
		@include breakpoint-down(mobile) {
			font-size: 5.2rem;
			font-weight: 700; 
			margin-bottom: 2rem;
		}
	}

	.accordion__actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.accordion__actions .btn {
		padding: 1.45rem 5.6rem;

		@include breakpoint-down(mobile) {
			padding: 1.6rem 5.6rem;
			flex: 0 1 100%;
		}

		&:hover {
			background-color: $c-gray-2; 
		}
	}

	.accordion__section.is-current .accordion__actions .btn {
		@include breakpoint-down(mobile) {
			padding: 1.8rem 5.6rem;
		}
	}

	.accordion__actions * + * {
		margin-left: 1.3rem;

		@include breakpoint-down(mobile) {
			margin-left: 1.2rem;
		}
	}

	.accordion__section.is-current .accordion__actions * + * {
		@include breakpoint-down(mobile) {
			margin-left: 1rem;
		}
	}
	
	.accordion__trigger {
		color: $c-yellow; 
		border: .1rem solid currentColor;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 4.9rem; 
		height: 4.9rem; 
		transition: color $t-duration;

		@include breakpoint-down(mobile) {
			width: 4.9rem;
			height: 4.9rem;
			flex: 0 0 4.9rem;
		}

		&:hover {
			color: $c-black;
		}
	}

	.accordion__section.is-current .accordion__trigger {
		@include breakpoint-down(mobile) {
			width: 5.3rem;
			height: 5.3rem;
			flex: 0 0 5.3rem;
		}
	}

	.accordion__trigger svg {
		width: 1.2rem;
		transform: rotateZ(90deg);
		transition: transform $t-duration;
	}

	.accordion__section.is-current .accordion__trigger svg {
		transform: rotateZ(-90deg);
	}

	.accordion__body {
		padding: 6rem calc((100vw - 83rem) / 2) 9.3rem;
		color: $c-gray-4;
		position: relative;
		isolation: isolate;

		@include breakpoint-down(tablet) {
			padding: 6rem max(calc((100vw - 81.6rem) / 2), 3rem) 9.3rem;
		}

		@include breakpoint-down(mobile) {
			padding: 8.1rem $shell-gutter-mobile 2.4rem;
		}
	}

	.accordion__body .accordion__image {
		position: absolute;
		top: 9.1rem; 
		right: 0;
		width: 35.63%; 
		z-index: -1;
	}

	.accordion__body .accordion__image img {
		width: 100%; 
		height: auto;
	}

	.accordion__body h5 {
		color: $c-black-off;
		margin-bottom: 0;
		padding-bottom: 2rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			padding-bottom: 1.5rem;
		}
	}

	.accordion__body h5:last-of-type + p {
		margin-top: 1.3rem;

		@include breakpoint-down(mobile) {
			margin-top: 0;
		}
	}

	.accordion__body p {
		margin-bottom: 0;
		padding-bottom: 1.9rem;

		@include breakpoint-down(mobile) {
			padding-bottom: .6rem;
		}
	}

	.accordion__body p + p {
		margin-top: 1.4rem;

		@include breakpoint-down(mobile) {
			margin-top: 2.2rem;
		}
	}

	.accordion__body p + h5 {
		margin-top: 4.1rem; 

		@include breakpoint-down(mobile) {
			margin-top: 3.4rem;
		}
	}

	.accordion__body .list-dotted {
		margin-bottom: 0;
		padding-bottom: 2rem;
	}

	.accordion__body .list-dotted + h5 {
		margin-top: 4rem;

		@include breakpoint-down(mobile) {
			margin-top: 2rem;
		}
	}

	.accordion__body .accordion__actions {
		margin-top: 4rem;
		margin-right: -9rem; 

		@include breakpoint-down(small-desktop) {
			margin-right: -5rem;
		}

		@include breakpoint-down(tablet) {
			margin-right: 0;
		}

		@include breakpoint-down(mobile) {
			margin-top: 2.2rem;
		}
	}
}