/* ------------------------------------------------------------ *\
	Testimonial
\* ------------------------------------------------------------ */

.testimonial {
	background-color: $c-white;
	color: $c-black-off;
	padding: 3.2rem 4rem 5.3rem;
	display: flex;
	flex-direction: column;
	height: 100%;

	@include breakpoint-down(mobile) {
		font-size: 1.6rem;
		padding: 3.2rem 4rem 5rem;
	}
	
	.testimonial__bar {
		display: flex;
		align-items: flex-start;
		margin-bottom: 2rem;
	}
	
	.testimonial__bar figure {
		flex: 0 0 5.8rem;
		height: 5.8rem; 
		margin-right: 1.4rem;
	}

	.testimonial__head {
		flex: 0 1 100%;
	}

	.testimonial__head h5 {
		font-size: 2rem;
		line-height: 1.4;
		margin-bottom: 1.2rem;
		font-weight: bold;
	}

	.testimonial__head h6 {
		text-transform: uppercase;
		font-size: 1.3rem;
		line-height: 1.23;
		color: $c-gray-3;
		letter-spacing: 0.08em; 
	}

	.testimonial__body {
		color: $c-gray-4;

		@include breakpoint-down(mobile) {
			padding-right: 1rem;
		}
	}
	
	.testimonial__body p small {
		font-size: 1.4rem;
	}
}

.testimonial--small {
	@include breakpoint-down(mobile) {
		padding: 3.7rem 3rem 5rem;
		font-size: 1.4rem;
		line-height: 2.28;
	}

	.testimonial__bar {
		@include breakpoint-down(mobile) {
			margin-bottom: 2rem;
		}
	}

	.testimonial__bar figure {
		@include breakpoint-down(mobile) {
			flex: 0 0 5rem;
			height: 5rem;
		}
	}

	.testimonial__head h5 {
		@include breakpoint-down(mobile) {
			font-size: 1.6rem; 
			line-height: 1.9;
			margin-bottom: 0;
		}
	}

	.testimonial__head h6 {
		@include breakpoint-down(mobile) {
			font-size: 1.1rem;
			line-height: 1.45;
			letter-spacing: 0.09em;
		}
	}

	.testimonial__body {
		@include breakpoint-down(mobile) {
			padding-right: .6rem;
		}
	}
}