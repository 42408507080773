/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.hero {
	padding: 16.1rem 0 5rem;
	background-color: $c-darkgreen;
	color: $c-white; 
	position: relative;
	font-size: 2rem;
	line-height: 1.4;

	@include breakpoint-down(mobile) {
		padding: 13.1rem 0 5rem;
		font-size: 1.6rem;
		line-height: 1.63;
		font-weight: 400;
	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0; 
		left: 0; 
		width: 100%; 
		background-color: $c-white;
		padding-bottom: 12.1rem;
		z-index: 1;

		@include breakpoint-down(small-desktop) {
			padding-bottom: 4rem;
		}

		@include breakpoint-down(mobile) {
			padding-bottom: 13.1rem;
		}
	}

	.hero__background {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;
	}

	.hero__background img {
		position: absolute;
		top: 0;
		left: 0; 
		width: 100%; 
		height: 100%; 
		object-fit: cover;
		mix-blend-mode: multiply;
	}

	.shell {
		position: relative;
		z-index: 2; 
	}

	.hero__inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.hero__content {
		flex: 0 0 46%;
		max-width: 46%;
		padding-bottom: 5rem;

		@include breakpoint-down(small-desktop) {
			flex: 0 0 45%;
			max-width: 45%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-bottom: 11.3rem;
		}
	}

	.hero__content h2 {
		margin-bottom: 3.3rem;
		font-weight: bold;

		@include breakpoint-down(tablet) {
			font-size: 6rem;
		}

		@include breakpoint-down(mobile) {
			font-size: 5.2rem;
			line-height: 1.2;
			font-weight: 700;
			margin-bottom: 1.9rem;
		}
	}

	.hero__content p {
		margin-bottom: 0;
	}

	.hero__content p + p {
		margin-top: 2.8rem;

		@include breakpoint-down(mobile) {
			margin-top: 2.6rem;
		}
	}

	.hero__content p + .btn {
		margin-top: 3.5rem;

		@include breakpoint-down(mobile) {
			margin-top: 3.1rem;
		}
	}

	.hero__content .btn {
		@include breakpoint-down(mobile) {
			max-width: 100%;
			width: 100%;
		}
	}

	.hero__content .ornament {
		margin-bottom: 1.3rem;

		@include breakpoint-down(mobile) {
			max-width: 27.2rem;
			margin-left: .4rem;
			margin-bottom: .6rem;
		}
	}

	.hero__aside {
		flex: 0 0 51.3%;
		max-width: 51.3%;
		position: relative;

		@include breakpoint-down(small-desktop) {
			flex: 0 0 45%;
			max-width: 45%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.hero__aside address {
		position: absolute;
		top: 8rem;
		right: -1rem; 
		transform: translateX(50%);
		font-size: 1.35rem;
		line-height: 1.2;
		text-transform: uppercase;
		color: $c-yellow;
		font-weight: 700;
		letter-spacing: 0.1em;

		@include breakpoint-down(large-desktop) {
			transform: none;
			right: 0;
		}

		@include breakpoint-down(mobile) {
			font-size: 1rem;
			line-height: 1.2;
			top: 6.2rem;
			bottom: 0; 
		}

		&:after {
			@include breakpoint-down(mobile) {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				top: 5.1rem;
				width: .1rem;
				background-color: currentColor; 
			}
		}
	}

	.hero__image {
		max-width: 89.4%;
		position: relative;

		@include breakpoint-down(mobile) {
			max-width: 69.5%;
			padding-bottom: 87.2%;
		}
	}

	.hero__image img {
		width: 100%;
		height: auto;

		@include breakpoint-down(mobile) {
			position: absolute;
			width: 100%; 
			height: 100%; 
			object-fit: cover;
		}
	}

	.hero__image > figure {
		position: absolute;
		bottom: 0; 
		left: 0; 
		width: 13.4rem;
		font-size: 0;
		transform: translate(-50%, 0);

		@include breakpoint-down(mobile) {
			bottom: auto;
			top: .5rem;
			left: .7rem;
			transform: translate(-50%, -50%);
			width: 13.9rem;
		}
	}

	.hero__image > figure svg,
	.hero__image > figure img {
		width: 100%; height: auto;
	}

	.hero__head {
		position: absolute;
		top: 3.5rem; 
		right: -.2rem; 
		transform: rotateZ(-90deg);
		transform-origin: bottom right;
		display: flex;
		align-items: flex-end;
		border-bottom: .2rem solid $c-yellow;
		color: $c-yellow;
		font-weight: 700;
		font-size: 1.35rem;
		line-height: 1.2;

		@include breakpoint-down(mobile) {
			border-bottom: 0; 
			flex-wrap: wrap;
			transform: translateY(-50%);
			margin-top: -2.2rem;
			font-size: 1rem;
			top: 0;
			right: .2rem;

		}
	}

	.hero__head span {
		display: inline-block;
		text-transform: uppercase;
		padding-bottom: .6rem;
		letter-spacing: 0.1em;

		@include breakpoint-down(mobile) {
			position: absolute;
			top: 1.5rem; 
			left: .6rem;
		}
	}

	.hero__head span + h1 {
		margin-left: 1.5rem;

		@include breakpoint-down(mobile) {
			margin-left: 0;
		}
	}

	.hero__head h1 {
		display: inline-block;
		font-family: $ff-garamond; 
		font-weight: 500; 
		line-height: .8;
		vertical-align: text-bottom;
		margin-right: -.2rem; 

		@include breakpoint-down(mobile) {
			width: 100%;
		}
	}

	.hero__scroll {
		position: absolute;
		z-index: 3;
		bottom: 12.8%;
		left: 1.3rem;
		padding-left: .5rem;
		letter-spacing: 0.075em;
		padding-bottom: 1.4rem;
		font-size: 1.3rem;
		text-transform: uppercase;
		font-weight: 700;
		color: $c-yellow;
		text-decoration: none;

		@include breakpoint-down(small-desktop) {
			bottom: 0; 
		}
	}

	.hero__scroll i {
		position: absolute;
		top: 100%;
		left: 0;
	}
}

.hero--alt {
	padding: 18.1rem 0 2rem; 

	@include breakpoint-down(mobile) {
		padding: 15.4rem 0 2rem; 
		font-size: 1.4rem;
		line-height: 2;
		font-weight: 500;
	}

	&:after {
		display: none;
	}

	.hero__content {
		flex: 0 0 39%;
		max-width: 39%;

		@include breakpoint-down(tablet) {
			flex: 0 0 45%;
			max-width: 45%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 .4rem 4.6rem 0;
		}
	}
	
	.hero__content h3 {
		margin-bottom: 4rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 5.2rem;
			line-height: 1.2;
			margin-bottom: 1.5rem;
		}
	}

	.hero__aside {
		flex: 0 0 54%;
		max-width: 54%;
		padding-top: 11.3rem;

		@include breakpoint-down(tablet) {
			flex: 0 0 48%;
			max-width: 48%;
		}

		@include breakpoint-down(mobile) {
			padding-top: 0;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.hero__image {
		max-width: 100%; 
		height: auto;
		margin-bottom: -11.7rem;

		@include breakpoint-down(mobile) {
			padding-bottom: 84.9%;
			margin-bottom: -7rem;
		}
	}

	.hero__image figure {
		width: 11.2rem;
		bottom: 20.9rem;
		left: 0.3rem; 

		@include breakpoint-down(mobile) {
			bottom: 50%;
			transform: translate(-50%, 50%);
			left: 0;
			margin-bottom: -3rem;
		}
	}

	.hero__image figure img {

		width: 100%; 
		height: auto;
	}
}

.hero--contact {
	padding: 20.5rem 0 2rem; 

	@include breakpoint-down(mobile) {
		padding: 14.7rem 0 2rem; 
		font-weight: 500;
	}

	&:after {
		background-color: $c-beige; 
		padding-bottom: 7.2rem;

		@include breakpoint-down(small-desktop) {
			padding-bottom: 4rem;
		}

		@include breakpoint-down(mobile) {
			padding-bottom: 6rem;
		}
	}

	.hero__background {
		mix-blend-mode: multiply;
	}	

	.hero__background img {
		mix-blend-mode: none;
	}

	.hero__inner {
		@include breakpoint-down(mobile) {
			flex-wrap: wrap-reverse;
		}
	}

	.hero__aside {
		flex: 0 0 48.7%;
		max-width: 48.7%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}	

	.hero__head {
		transform: translateY(100%) rotateZ(-90deg);
		transform-origin: bottom left;
		margin-top: 26.1%;
		margin-left: 12.1rem;
		padding-bottom: 2rem;
		right: auto;
		left: 0;

		@include breakpoint-down(mobile) {
			transform: translateY(-50%) rotateZ(0);
			top: 0; 
			right: .2rem;
			left: auto;
			margin-top: -.1rem;
		}
	}

	.hero__head h1 {
		@include breakpoint-down(mobile) {
			font-size: 9.3rem;
			line-height: 1.31;
			transform-origin: center;
			font-weight: 500;
		}
	}

	.hero__image {
		margin-left: auto;
		max-width: 82.5%;

		@include breakpoint-down(mobile) {
			margin-left: 0;
			max-width: 69.3%;
		}
	}

	.hero__image > figure {
		position: absolute;
		z-index: -1;
		bottom: 18.6%; 
		left: auto;
		right: 0; 
		transform: translateX(50%);
		width: 45.768%;
	}

	.hero__content {
		flex: 0 0 40.1%;
		max-width: 40.1%;
		font-size: 1.6rem;
		line-height: 2;
		position: relative;

		@include breakpoint-down(tablet) {
			flex: 0 0 45%;
			max-width: 45%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-bottom: 4rem;
		}
	}

	.hero__content h3 {
		margin-bottom: 2rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 5.2rem;
			line-height: 1.2;
		}
	}

	.hero__content p + .hero__cols {
		margin-top: 3.1rem;

		@include breakpoint-down(mobile) {
			margin-top: 2.1rem;
		}
	}

	.hero__cols {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 -.8rem; 

		@include breakpoint-down(small-desktop) {
			margin: 0 0 -1rem; 
		}

		@include breakpoint-down(mobile) {
			margin: 0 0 -2.2rem; 
		}
	}

	.hero__col {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0 .8rem; 

		@include breakpoint-down(small-desktop) {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 0 1rem; 
		}

		@include breakpoint-down(mobile) {
			padding: 0 0 2.2rem;
		}
	}

	.hero__col .btn {
		margin-top: .9rem;
		padding: 1.6rem 7.2rem;

		@include breakpoint-down(mobile) {
			padding: 1.9rem 7.2rem;
		}
	}

	.socials {
		margin-top: 6rem;
		max-width: 50%;
		width: 100%; 
		padding-left: .8rem;
		margin-left: auto;

		@include breakpoint-down(small-desktop) {
			max-width: unset;
			margin-top: 1rem;
			padding-left: 0;
		}

		@include breakpoint-down(mobile) {
			position: absolute;
			bottom: 6.1rem;
			right: 0;
			left: auto; 
			max-width: max-content;
			padding-left: 1rem;
		}
	}
}