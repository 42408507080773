/* ------------------------------------------------------------ *\
	Image
\* ------------------------------------------------------------ */

.image {
	position: relative;
	padding-bottom: 94%;

	@include breakpoint-down(mobile) {
		padding-bottom: 99%;
	}

	img {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}
}