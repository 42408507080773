/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	padding: 5rem 0 9rem;
	overflow: hidden;

	@include breakpoint-down(mobile) {
		padding: 4.6rem 0 5.8rem;
	}

	.section__head {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.section__head-aside {
		flex: 0 0 31.7%;
		max-width: 31.7%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.section__head-aside h3 {
		margin-bottom: 1rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			margin-bottom: 1.6rem;
		}
	}

	.section__head-aside > :where(p) {
		max-width: 76%;
		padding-left: .2rem;
		margin-bottom: 6rem;
		color: $c-gray-4; 

		@include breakpoint-down(tablet) {
			max-width: unset;
			margin-bottom: 4rem;
		}

		&:last-child {
			margin-bottom: 0;

			@include breakpoint-down(mobile) {
				margin-bottom: 0;
			}	
		}
	}

	.section__head-content {
		margin-left: 1.9%;
		flex: 1 0 66%;
		margin-right: calc((100vw - 100%) / -2);
		padding-top: 5.8rem;

		@include breakpoint-down(mobile) {
			margin-left: 0;
			flex: 0 0 100%;
			max-width: 100%;
			padding-top: 2.8rem;
		}
	}

	.section__body {
		padding-top: 1.6rem;

		@include breakpoint-down(mobile) {
			padding-top: 0;
		}
	}
}