/* ------------------------------------------------------------ *\
	Section Default
\* ------------------------------------------------------------ */

.section-default {
	background-color: $c-yellow;
	padding: 5.5rem 0;
	color: $c-white; 

	@include breakpoint-down(mobile) {
		padding: 6rem 0 4.8rem; 
	}

	.section__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.section__content {
		flex: 0 0 48.8%;
		max-width: 48.8%;
		padding-top: 21.5%;
		padding-left: 10.6rem;
		font-size: 1.4rem;
		position: relative;

		@include breakpoint-down(tablet) {
			padding-left: 0;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-top: 57.1%;
			padding-bottom: 4rem;
		}
	}


	.section__content .section__image {
		position: absolute;
		top: -6rem;
		left: 0;
		margin-top: 3.3rem;
		transform: translateY(-50%);
		max-width: 65%;
		width: 100%;
		padding-bottom: 62.635%;

		@include breakpoint-down(mobile) {
			left: -#{$shell-gutter-mobile};
			max-width: 71.4%;
			top: -6rem;
			padding-bottom: 81.345%;
		}
	}


	.section__content .section__image .section__stamp {
		position: absolute;
		bottom: 7%; 
		right: 0; 
		max-width: 14.6rem;
		width: 100%;
		transform: translateX(50%);

		@include breakpoint-down(mobile) {
			max-width: 10.8rem;
			bottom: 3%; 
		}
	}
	
	.section__stamp svg {
		width: 100%; 
		height: auto;
	}

	.section__content h3 {
		margin-bottom: .5rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			max-width: 75%;
			margin-bottom: 1.7rem;
		}
	}

	.section__content p {
		margin-bottom: 0;
		max-width: 40rem;
	}

	.section__content p:not(:last-child) {
		margin-bottom: 2.8rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 3rem;
		}
	}

	.section__content .btn {
		@include breakpoint-down(mobile) {
			padding: 1.9rem 8.2rem; 
		}
	}

	.section__image {
		flex: 0 0 48.8%;
		max-width: 48.8%;
		position: relative;
		padding-bottom: 56.62%;

		@include breakpoint-down(mobile) {
			margin-left: -#{$shell-gutter-mobile};
			flex: 0 0 83%;
			max-width: 83%;
			padding-bottom: 95.62%;
		}
	}

	.section__image img {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}
}

.section-default--alt {
	position: relative;
	padding: 12.3rem 0 0; 

	@include breakpoint-down(mobile) {
		padding: 7.2rem 0 3rem; 
	}

	&:after {
		content: "";
		position: absolute; 
		bottom: 0; 
		left: 0; 
		width: 100%; 
		padding-bottom: 9.6rem;
		background-color: $c-white;  
		z-index: 0; 

		@include breakpoint-down(small-desktop) {
			padding-bottom: 7rem;
		}

		@include breakpoint-down(mobile) {
			padding-bottom: 13.4rem;
		}
	}

	.section__pattern {
		position: absolute;
		bottom: 0; 
		left: 0; 
		top: 4.1rem;
		width: 100%;
		mix-blend-mode: multiply;
	}

	.section__pattern img {
		width: 100%; 
		height: 100%;
		object-fit: cover;
	}

	.shell {
		position: relative;
		z-index: 1;
	}

	.section__content {
		flex: 0 0 27.1%;
		max-width: 27.1%;
		padding-left: .3rem;
		padding-top: 12.7%; 
		line-height: 2; 
		font-size: 1.6rem;

		@include breakpoint-down(small-desktop) {
			flex: 0 0 29%;
			max-width: 29%;
		}

		@include breakpoint-down(tablet) {
			flex: 0 0 35%;
			max-width: 35%;
			padding-bottom: 9rem;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 0 4.4rem 0.3rem;
			font-size: 1.4rem;
			line-height: 2.29;
		}
	}

	.section__content h4 {
		margin-bottom: 3.3rem; 
		margin-left: -.3rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 2.8rem;
			line-height: 1.68; 
			margin: 0 0 2rem;
		}
	}

	.section__stamp {
		position: absolute;
		top: 50%; 
		margin-top: -2.2rem;
		left: 0; 
		transform: translate(-50%, -50%);
		width: 14.6rem;
		height: 14.6rem;

		@include breakpoint-down(mobile) {
			left: auto;
			right: .3rem;
			transform: translate(50%, -50%);
			width: 12rem;
			height: 12rem;
			margin-top: -2.5rem;
		}
	}

	.section__image {
		flex: 0 0 49%;
		max-width: 49%;
		padding-bottom: 57.52%;
		margin-right: .7%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			margin: 0;
			padding-bottom: 115.52%;
		}
	}
}

.section-callout--alt + .section-default--alt {
	margin-top: -8.8rem;

	@include breakpoint-down(mobile) {
		margin-top: 0;
	}
}