/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */

//@import '~vendor-name/vendor.scss/css';
@import '~slick-carousel/slick/slick.css';
@import "bootstrap/scss/bootstrap";
@import "aos/src/sass/aos";

/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */

@import 'generic/variables';
@import 'generic/functions';
@import 'generic/mixins';
@import 'generic/reset';
@import 'generic/fonts';
@import 'generic/base';
@import 'generic/helpers';

/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */

@import '_sprite.css';
@import "components/_accordion-small.scss";
@import "components/_accordion.scss";
@import "components/_background-wrapper.scss";
@import "components/_btn.scss";
@import "components/_cart.scss";
@import "components/_checkbox.scss";
@import "components/_copy.scss";
@import "components/_field.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_grid.scss";
@import "components/_header.scss";
@import "components/_hero.scss";
@import "components/_icons.scss";
@import "components/_image.scss";
@import "components/_list-dotted.scss";
@import "components/_list-faq.scss";
@import "components/_list-info.scss";
@import "components/_list-props.scss";
@import "components/_logo.scss";
@import "components/_main.scss";
@import "components/_member.scss";
@import "components/_modal.scss";
@import "components/_nav-trigger.scss";
@import "components/_nav.scss";
@import "components/_ornament.scss";
@import "components/_product-small.scss";
@import "components/_product.scss";
@import "components/_products.scss";
@import "components/_radio.scss";
@import "components/_section-about.scss";
@import "components/_section-asymetric.scss";
@import "components/_section-background.scss";
@import "components/_section-callout.scss";
@import "components/_section-contact.scss";
@import "components/_section-default.scss";
@import "components/_section-faq.scss";
@import "components/_section-members.scss";
@import "components/_section-plain.scss";
@import "components/_section-privacy.scss";
@import "components/_section-social.scss";
@import "components/_section-testimonials.scss";
@import "components/_section.scss";
@import "components/_select.scss";
@import "components/_shell.scss";
@import "components/_slider.scss";
@import "components/_social.scss";
@import "components/_socilas.scss";
@import "components/_subscribe.scss";
@import "components/_tab.scss";
@import "components/_tabs.scss";
@import "components/_testimonial.scss";
@import "components/_widget.scss";
@import "components/_wrapper.scss";

/* ------------------------------------------------------------ *\
	Overwrites
\* ------------------------------------------------------------ */

//@import 'overwrites/';

.no-padding{
  padding: 0 !important;
}

.no-margin{
  margin: 0 !important;
}

a:hover{
  color: black;
}