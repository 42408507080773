/* ------------------------------------------------------------ *\
	Product Small
\* ------------------------------------------------------------ */

.product-small {
	.product__image {
		max-width: 60.05%;
		margin: 0 auto 1.8rem;
		padding-bottom: 60.05%;
		position: relative;

		@include breakpoint-down(mobile) {
			max-width: 68.805%;
			padding-bottom: 68.805%;
		}
	}

	.product__image img {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}

	.product__content {
		padding-bottom: 3.5rem;

		@include breakpoint-down(mobile) {
			padding-left: .3rem;
		}
	}

	.product__content h6 {
		max-width: 10.1rem;
		letter-spacing: 0.07em;
	}
	
	.product__content h6 a {
		text-decoration: underline;
		text-decoration-color: transparent;
		transition: text-decoration-color $t-duration;

		&:hover {
			text-decoration-color: currentColor;
		}
	}
}