/* ------------------------------------------------------------ *\
	Social
\* ------------------------------------------------------------ */

.social {
	.ico-instagram {
		color: $c-yellow; 
		margin-bottom: 3.2rem;
		margin-left: .5rem;

		@include breakpoint-down(mobile) {
			margin-left: .2rem;
			margin-bottom: 1.5rem;
		}
	}

	.ico-instagram svg {
		@include breakpoint-down(mobile) {
			width: 2.5rem; 
		}
	}

	h5 {
		font-size: 3rem;  
		font-weight: 700; 
		margin-bottom: 4.6rem;

		@include breakpoint-down(mobile) {
			font-size: 2.8rem; 
			margin-bottom: 3.6rem;
		}
	}

	.btn {
		@include breakpoint-down(mobile) {
			max-width: 100%;
			width: 100%;
		}
	}
}

.social--alt {
	.ico-instagram {
		margin-bottom: 4.2rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 1.6rem;
		}
	}

	h5 {
		margin-bottom: 5.5rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 3.5rem;
		}
	}
}