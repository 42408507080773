/* ------------------------------------------------------------ *\
	Section Social
\* ------------------------------------------------------------ */

.section-social {
	padding: 7rem 0 17.4rem;
	position: relative;

	@include breakpoint-down(mobile) {
		padding: 4.3rem 0 0;
	}

	.section__inner {
		max-width: 91.5%;
		margin-left: auto;
		background-color: $c-beige; 
		position: relative;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 14.3rem 0 5.6rem;
		color: $c-black-off;

		@include breakpoint-down(mobile) {
			max-width: unset;
			margin: 0 -#{$shell-gutter-mobile}; 
			padding: 17.8rem $shell-gutter-mobile 6.6rem;
			flex-wrap: wrap-reverse;
			isolation: isolate;
		}
	}

	.section__inner .section__pattern {
		position: absolute;
		z-index: -1; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		opacity: .1;
		mix-blend-mode: multiply;
	}

	.section__inner .section__pattern img {
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}

	.section__head {
		position: absolute;
		top: 0; 
		left: 0; 
		transform: rotateZ(-90deg) translateX(-100%);
		margin-top: 3.1rem;
		margin-left: -15.2rem; 
		color: $c-yellow; 
		border-bottom: .2rem solid currentColor; 
		transform-origin: top left;

		@include breakpoint-down(large-desktop) {
			margin-left: -12rem;
		}

		@include breakpoint-down(tablet) {
			margin-left: -9rem;
		}

		@include breakpoint-down(mobile) {
			z-index: 2;
			margin-left: 1.6rem;
			min-width: 36rem;
		}
	}

	.section__head h1 {
		font-family: $ff-garamond;
		font-weight: 500;  
		margin-bottom: 0;
		margin-right: -1.2rem; 

		@include breakpoint-down(mobile) {
			font-size: 8.6rem;
			line-height: 1.1;
			margin-right: 0.4rem; 
			margin-left: 1.6rem;
		}
	}

	.section__content {
		flex: 0 0 35%;
		max-width: 35%;
		padding-left: 12.435%;		
		padding-bottom: 6.8rem;
		align-self: center;

		@include breakpoint-down(small-desktop) {
			flex: 0 0 43%;
			max-width: 43%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 4.2rem 0 0;
		}
	}

	.section__aside {
		flex: 0 0 53.3%;
		max-width: 53.3%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.section__aside .slider {
		@include breakpoint-down(mobile) {
			max-width: 82.3%;
			margin-left: auto;
		}
	}
}

.section-social--alt {
	padding: 7rem 0 12.2rem;

	@include breakpoint-down(mobile) {
		padding: 0;
	}

	.section__content {
		padding-bottom: 7rem;

		@include breakpoint-down(mobile) {
			padding-bottom: 0;
		}
	}
}