/* ------------------------------------------------------------ *\
	Nav Trigger
\* ------------------------------------------------------------ */

.nav-trigger {
	display: inline-block;
	width: 2.4rem; 
	height: 1.4rem; 
	position: relative;

	span {
		position: absolute;
		height: .2rem;
		width: 100%;
		top: 50%;
		left: 0; 
		transform: translateY(-50%);
		background-color: currentColor;
		transition: opacity $t-duration;
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height: .2rem;
		width: 100%;
		background-color: currentColor; 
		margin-top: -.6rem;
		transition: margin $t-duration, transform $t-duration;
	}

	&:after {
		margin-top: .6rem;
	}
}

.is-active .nav-trigger {
	span {
		opacity: 0;
	}

	&:before {
		margin-top: 0;
		transform: translateY(-50%) rotateZ(45deg);
	}

	&:after {
		margin-top: 0;
		transform: translateY(-50%) rotateZ(-45deg);
	}
}