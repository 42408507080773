/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider--default {
	--paging-width: 25.7rem;
	--arrow-width: 1.2rem;

	.slick-list {
		margin-left: -1.5rem;
		margin-right: calc((100vw - 100%) / -2); 

		@include breakpoint-down(mobile) {
			margin-left: -1rem;
			margin-right: -#{$shell-gutter-mobile};
		}
	}

	.slick-track {
		display: flex;
		align-items: stretch;
	}

	.slick-slide {
		padding: 0 1.5rem;
		height: auto;

		@include breakpoint-down(mobile) {
			padding: 0 1rem;
		}
	}

	.slick-slide > * {
		height: 100%;
	}

	.slider__clip {
		position: relative;
	}

	.slider__slides {
		@include breakpoint-down(mobile) {
			padding-bottom: 8.5rem;	
		}
	}

	.slider__slide {
		height: 100%;
	}

	.slider__prev,
	.slider__next {
		position: absolute;
		z-index: 2; 
		top: 0; 
		right: .8rem;
		margin-top: -15rem;
		font-size: 0;
		display: inline-block;

		@include breakpoint-down(mobile) {
			margin-top: 0;
			top: auto;
			margin-right: -2.7rem;
			bottom: 0; 
			right: calc((100vw - var(--paging-width)) / 2); 
		}
	}

	.slider__prev {
		margin-right: calc(var(--paging-width) + var(--arrow-width));

		@include breakpoint-down(mobile) {
			margin-right: auto;
			margin-left: -2.7rem;
			right: auto;
			left: calc((100vw - var(--paging-width)) / 2); 
		}
	}

	.slider__prev svg,
	.slider__next svg {
		width: var(--arrow-width);
		height: auto;
	}

	.slider__paging {
		position: absolute;
		top: -15.9rem;
		right: .8rem;
		width: var(--paging-width);
		margin-right: var(--arrow-width);
		padding: 0 3.1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 2rem;
		font-weight: 700;

		@include breakpoint-down(mobile) {
			z-index: 2;
			top: auto;
			bottom: 0;
			right: 0; 
			left: 0;
			margin: 0 auto -1.1rem;
		}
	}

	.slider__paging i {
		display: inline-block;
		height: .1rem; 
		flex: 0 1 100%;
		background-color: currentColor; 
	}

	.slider__paging span {
		&:first-child {
			margin-right: 2.4rem;
		}

		&:last-child {
			margin-left: 1.9rem;
		}
	}
}

.slider--default-alt {
	.slick-list {
		@include breakpoint-down(mobile) {
			margin-left: -1.5rem;
			margin-right: -#{$shell-gutter-mobile};
		}
	}

	.slick-slide {
		@include breakpoint-down(mobile) {
			padding: 0 1.5rem; 
		}
	}

	.slider__prev {
		@include breakpoint-down(mobile) {
			margin-right: auto;
			margin-left: -3rem;
			right: auto;
			left: calc((100vw - var(--paging-width)) / 2); 
		}
	}

	.slider__next {
		@include breakpoint-down(mobile) {
			margin-top: 0;
			top: auto;
			margin-right: -2.7rem;
			bottom: 0; 
			right: calc((100vw - var(--paging-width)) / 2); 
		}
	}
}

.slider--gallery {
	@include breakpoint-down(mobile) {
		font-size: 0;
	}

	.slick-list {
		margin-left: -1.5rem;
		margin-right: calc( ( 100vw - ( ( ( 100% * 0.88 ) + 100% ) + ( ( ( 100% * 0.88 ) + 100% ) * 0.09 ) ) ) / -2 );

		@include breakpoint-down(mobile) {
			margin-left: 0;
			margin-right: -#{$shell-gutter-mobile};
		}
	}

	.slick-slide {
		padding: 0 1.5rem;

		@include breakpoint-down(mobile) {
			padding: 0;
		}
	}

	.slider__slides {
		padding-bottom: 7.6rem;

		@include breakpoint-down(mobile) {
			padding-bottom: 0;
		}
	}

	.slider__prev,
	.slider__next {
		position: absolute;
		bottom: 0; 
		right: 11.5rem;
		font-size: 0;
		transition: color;

		> svg{
			transition: color $t-duration;
		}

		&:hover{
			> svg{
				color: black;
			}
		}
	}

	.slider__prev {
		margin-right: 4.4rem;
	}

	.slider__prev svg,
	.slider__next svg {
		color: $c-yellow;
	}
}

.slider--members {
	@include breakpoint-down(mobile) {
		padding-left: .5rem;
		--paging-width: 25.8rem;
	}

	.slick-track {
		display: flex;
		align-items: stretch;
	}

	.slick-list {
		margin-right: -1.5rem;

		@include breakpoint-down(mobile) {
			margin-left: -1.3rem;
		}
	}

	.slider__slides {
		@include breakpoint-down(mobile) {
			padding-bottom: 7.5rem;
		}
	}

	.slick-slide {
		@include breakpoint-down(mobile) {
			padding: 0 1.3rem;
		}
	}

	.slider__prev {
		@include breakpoint-down(mobile) {
			margin-left: -3.3rem;
		}
	}
}