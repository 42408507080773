/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: absolute;
	z-index: 10; 
	top: 0; 
	left: 0; 
	width: 100%;
	color: $c-white; 
	padding: 2.8rem 0;
	font-size: 1.3rem;
	line-height: 1.2;
	isolation: isolate;
	transition: background-color $t-duration;

	@include breakpoint-down(tablet) {
		padding: 4.1rem 0;
	}

	.header__pattern {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		mix-blend-mode: multiply;
		z-index: -1;
	}

	.header__pattern img {
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}

	.header__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: .7rem;

		@include breakpoint-down(mobile) {
			padding-right: .3rem;
		}
	}

	.header__inner .logo {
		flex-shrink: 0;

		@include breakpoint-down(tablet) {
			position: absolute;
			top: 4.1rem;
			left: 50%; 
			transform: translate(-50%, -25%);
		}

		@include breakpoint-down(mobile) {
			top: 4.3rem;
		}
	}

	.header__menu {
		flex: 0 1 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 2.5rem; 

		@include breakpoint-down(tablet) {
			position: absolute;
			top: 80%;
			left: 0; 
			width: 100%;
			flex-wrap: wrap;
			opacity: 0;
			pointer-events: none;
			transition: opacity $t-duration, top $t-duration;
			background-color: $c-beige;
			padding: 1rem 1.5rem 2rem; 
			box-shadow: 0 1.2rem 1.2rem -1rem rgba(0, 0, 0, .7);
		}
	}

	.header__menu .nav {
		padding-left: 7rem;
		margin: 0 auto;

		@include breakpoint-down(tablet) {
			padding-left: 0;
			max-width: 100%;
			width: 100%;
		}
	}

	.header__menu > a {
		text-decoration: underline;
		text-decoration-color: transparent;
		transition: text-decoration-color $t-duration, color $t-duration;
		text-underline-offset: 0.7rem;

		@include breakpoint-down(tablet) {
			margin-top: 2rem;
		}

		&:hover {
			text-decoration: underline;
			text-anchor: underline;
			text-decoration-color: currentColor;
		}
	}


	.header__aside {
		display: flex;
		align-items: center;

		.ico-profile{
			transition: color $t-duration;

			&:hover{
				color: black;
			}
		}
	}

	.header__aside .nav-access + .cart {
		margin-left: 2.1rem;
	}

	&.is-active {
		background-color: $c-beige; 
		color: $c-black-off;
	}

	&.is-active .header__menu {  
		top: 100%;
		opacity: 1;
		pointer-events: all;
	}

	&.is-active .cart .cart__trigger span {
		border-color: $c-beige; 
	}
}

.header--white {
	color: $c-black;

	.cart .cart__trigger {
		border-color: $c-gray-3;
	}

	.cart .cart__trigger span {
		color: $c-white;
		border-color: $c-white;
	}
}

.header--green {
	background-color: $c-darkgreen;

	@include breakpoint-down(tablet) {
		background-color: $c-white; 
		color: $c-black;
	}

	.header__pattern {
		@include breakpoint-down(tablet) {
			display: none;
		}
	}

	.cart .cart__trigger {
		@include breakpoint-down(tablet) {
			border-color: $c-gray-3; 
		}
	}

	.cart .cart__trigger span {
		@include breakpoint-down(tablet) {
			border-color: $c-white;
			color: $c-white;
		}
	}
}