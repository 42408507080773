/* ------------------------------------------------------------ *\
	Section Privacy
\* ------------------------------------------------------------ */

.section-privacy {
	padding: 22.5rem 0 12.6rem;

	@include breakpoint-down(mobile) {
		padding: 14.7rem 0 9rem; 
	}

	.section__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.section__aside {
		flex: 0 0 31.7%;
		max-width: 31.7%;
		position: relative;
		isolation: isolate;

		@include breakpoint-down(tablet) {
			flex: 0 0 38%;
			max-width: 38%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.section__aside-image {
		position: absolute;
		z-index: -1; 
		bottom: 9.6%;
		left: 0; 
		width: 100%;   

		@include breakpoint-down(mobile) {
			bottom: auto;
			top: 100%;
			margin-top: 17.4rem; 
			width: 46.6rem; 
			left: -24.9rem;
		}
	}

	.section__aside-image img {
		width: 100%; 
		height: auto;
	}

	.section__content {
		flex: 0 0 57.3%;
		max-width: 57.3%;
		font-size: 1.4rem;
		line-height: 2; 
		font-weight: 500;
		padding-right: 8.9%;

		@include breakpoint-down(tablet) {
			padding-right: 0;
		}

		@include breakpoint-down(mobile) {
			position: relative;
			flex: 0 0 100%;
			max-width: 100%;
			padding-top: 7.1rem;
		}
	}

	.section__content h3 {
		max-width: 89%;
		padding-bottom: 2rem;
		margin-bottom: 0;

		@include breakpoint-down(mobile) {
			font-size: 5rem;
			max-width: unset;
		}
	}

	.section__content h3 + p {
		margin-top: 4.6rem;

		@include breakpoint-down(mobile) {
			margin-top: 1.2rem;
		}
	}

	.section__content p {
		margin-bottom: 0;
		padding-bottom: 2rem;

		@include breakpoint-down(mobile) {
			padding-right: .2rem;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}

	.section__content p + p {
		margin-top: 4rem;

		@include breakpoint-down(mobile) {
			margin-top: 2rem;
		}
	}
}