/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	.form__row + .form__row {
		margin-top: 2.9rem;
	}

	.form__controls {
		position: relative;
	}

	.form__hint {
		position: absolute;
		top: 50%; 
		right: 0; 
		transform: translateY(-50%);
		margin-top: -.5rem;
		color: $c-gray-6; 
		font-size: 1.6rem;
		line-height: 2;
	}

	.form__actions {
		margin-top: 4rem;

		@include breakpoint-down(mobile) {
			margin-top: 4.2rem;
		}
	}

	.form__btn {
		border: 0;
		border-radius: 0;
		padding: 1.5rem 5.6rem;

		@include breakpoint-down(mobile) {
			max-width: 100%;
			width: 100%; 
			padding: 1.9rem 5.6rem;
		}
	}
}