/* ------------------------------------------------------------ *\
	Section Faq
\* ------------------------------------------------------------ */

.section-faq {
	position: relative;
	padding: 14.4rem 0 10rem;
	color: $c-white; 

	@include breakpoint-down(tablet) {
		padding: 8rem 0;
	}

	@include breakpoint-down(mobile) {
		padding: 3.3rem 0 3.3rem;
	}

	.shell {
		position: relative;
	}

	.section__inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 10.6rem;

		@include breakpoint-down(large-desktop) {
			padding: 0;
		}
	}

	.section__content {
		flex: 0 0 42%;
		max-width: 42%;
		padding-bottom: 3.5rem;

		@include breakpoint-down(large-desktop) {
			flex: 0 0 43%;
			max-width: 43%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-bottom: 3.8rem;
		}
	}

	.section__content h3 {
		margin-bottom: 22.9rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 0;
		}
	}

	.section__aside {
		flex: 0 0 51.6%;
		max-width: 51.6%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.section__aside .list-faq + .btn {
		@include breakpoint-down(mobile) {
			margin-top: 1.6rem; 
		}
	}

	.section__aside .btn {
		@include breakpoint-down(mobile) {
			max-width: 100%;
			width: 100%;
		}
	}

	.section__background {
		position: absolute;
		top: 16%; 
		left: 0;
		width: 28.4%;
		font-size: 0; 

		@include breakpoint-down(tablet) {
			top: 35%;
		}
	}

	.section__background img {
		width: 100%; 
		height: auto;
	}
}

.section-faq--large {
	color: $c-black-off;
	padding: 26.6rem 0 28.3rem;

	@include breakpoint-down(tablet) {
		padding: 15rem 0 2.4rem;
	}

	.section__inner {
		padding: 0 .6rem 0 0;

		@include breakpoint-down(mobile) {
			padding: 0;
		}
	}	

	.section__content {
		font-size: 1.3rem;
		line-height: 1.23;  
		letter-spacing: 0.08em;
		margin-left: -.3rem; 

		@include breakpoint-down(large-desktop) {
			margin-left: 0;
		}

		@include breakpoint-down(mobile) {
			font-size: 1.1rem;
			line-height: 1.45;
			letter-spacing: 0.09em;
			padding-bottom: 3.9rem;
		}
	}

	.section__content h3 {
		margin-bottom: 2rem;
		margin-left: -.3rem; 
		letter-spacing: 0;

		@include breakpoint-down(large-desktop) {
			margin-left: 0;
		}

		@include breakpoint-down(mobile) {
			font-size: 5.2rem;
			line-height: 1.08;
			margin-bottom: 2.6rem;
		}
	}

	.section__content p {
		@include breakpoint-down(mobile) {
			max-width: 80%;
			margin-left: .2rem;
		}
	}

	.section__aside {
		flex: 0 0 49%;
		max-width: 49%;
		align-self: flex-start;
		padding-top: 2.1rem;

		@include breakpoint-down(mobile) {
			padding-top: 0;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}

.section-faq--size-1 {
	padding: 14.8rem 0 15.2rem;

	@include breakpoint-down(mobile) {
		padding: 4rem 0 7.6rem;
	}

	.section__content h3 {
		margin-bottom: 15.4rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			max-width: 97%;
			margin-bottom: 0;
		}
	}
}