/* ------------------------------------------------------------ *\
	List Dotted
\* ------------------------------------------------------------ */

.list-dotted {
	list-style: none;

	li {
		position: relative;
		padding-left: 2.5rem;

		@include breakpoint-down(mobile) {
			padding-left: 1.1rem;
		}

		&:before {
			content: "";
			position: absolute;
			top: 1.3rem; 
			left: 0;
			width: .5rem; 
			height: .5rem; 
			background-color: currentColor; 

			@include breakpoint-down(mobile) {
				width: .2rem;
				height: .2rem;
				top: 1.4rem;
			}
		}
	}

	li + li {
		margin-top: 1rem;

		@include breakpoint-down(mobile) {
			margin-top: .5rem;
		}
	}
}