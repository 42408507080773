/* ------------------------------------------------------------ *\
	Section About
\* ------------------------------------------------------------ */

.section-about {
	padding: 5.8rem 0 5.9rem;

	@include breakpoint-down(mobile) {
		padding: 9.1rem 0 0; 
	}

	.section__inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include breakpoint-down(mobile) {
			flex-wrap: wrap-reverse;
		}
	}

	.section__images {
		position: relative;
		flex: 0 0 51.415%;
		max-width: 51.415%; 
		align-self: flex-start;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: -3.9rem;
		}
	}

	.section__image {
		max-width: 63.4%;
		width: 100%;

		@include breakpoint-down(mobile) {
			position: relative;
			padding-bottom: 97.3%;
		}
	}

	.section__image img {
		width: 100%; 
		height: auto;

		@include breakpoint-down(mobile) {
			position: absolute;
			top: 0; 
			left: 0; 
			width: 100%; 
			height: 100%; 
			object-fit: cover;
		}
	}

	.section__image--overlay {
		max-width: 68.4%;
		position: absolute;
		bottom: 0; 
		right: 0;
		transform: translateY(50%);
		margin-bottom: -1.7rem;

		@include breakpoint-down(tablet) {
			margin-bottom: 0;
		}

		@include breakpoint-down(mobile) {
			max-width: 49.57%;
			bottom: auto;
			top: 2.8rem; 
			right: 0;
			transform: none;
			padding-bottom: 68.6%;
		}
	}

	.section__content {
		flex: 0 0 40%;
		max-width: 40%;
		padding-top: 15.8rem; 
		padding-right: 7.5%;

		@include breakpoint-down(tablet) {
			padding-right: 0;
			flex: 0 0 45%;
			max-width: 45%;
		}

		@include breakpoint-down(mobile) {
			padding: 0 0 4.6rem;
			flex: 0 0 100%;
			max-width: 100%;
			font-size: 1.6rem;
		}
	}

	.section__content h3 {
		margin-bottom: 3.2rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 5.2rem;
			line-height: 1.2;
			margin-bottom: 1.3rem;
		}
	}
}