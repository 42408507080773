/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget {
	background-color: $c-beige; 
	padding: 3.6rem 4.4rem 4.8rem;

	@include breakpoint-down(tablet) {
		padding: 3.6rem 4.4rem 4.8rem;
	}

	@include breakpoint-down(mobile) {
		padding: 3.6rem 4.5rem 4.6rem;
	}

	.widget__nav {
		font-size: 1.4rem;
		line-height: 2; 
		margin-bottom: 2.2rem;
	}

	.widget__nav ul {
		list-style: none;
		margin-bottom: 0;
	}

	.widget__nav li + li {
		margin-top: 2rem;
	}

	.widget__nav a {
		display: inline-block;
		width: 100%;
		text-decoration: underline;
		text-decoration-color: transparent;
		transition: text-decoration-color $t-duration;
	}
	
	.widget__nav li:hover a {
		text-decoration: underline;
		text-decoration-color: currentColor;
	}

	.ornament {
		max-width: 19.4rem;
	}
}