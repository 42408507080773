/* ------------------------------------------------------------ *\
	List Faq
\* ------------------------------------------------------------ */

.list-faq {
	list-style: none;
	font-size: 1.8rem;
	line-height: 1; 
	font-weight: 600; 
	margin-bottom: 0;

	@include breakpoint-down(mobile) {
		font-size: 1.4rem;
	}

	li + li {
		border-top: .1rem solid $c-gray; 
	}

	a {
		text-decoration: none;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 3.5rem 0 3.4rem 0.2rem;
		transition: color $t-duration;

		@include breakpoint-down(mobile) {
			padding: 3rem 0 2.9rem .2rem;
		}
	}

	li:hover a {
		color: $c-yellow; 
	}

	li:last-child a {
		padding: 3.5rem 0 3.5rem 0.2rem;

		@include breakpoint-down(mobile) {
			padding: 2.9rem 0 2.9rem .2rem;
		}
	}

	a i {
		margin-left: 2rem;

		@include breakpoint-down(mobile) {
			margin-left: 3.5rem;
			align-self: center;
		}
	}

	h5 {
		font-size: 1.8rem;	
		line-height: 1;
		font-weight: 600;
		margin-bottom: 0;

		@include breakpoint-down(mobile) {
			font-size: 1.4rem;
			line-height: 1.29;
		}
	}
}