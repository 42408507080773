/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	font-weight: 600;

	ul {
		list-style: none;
		display: flex;
		align-items: center;

		@include breakpoint-down(tablet) {
			flex-direction: column;
			align-items: stretch;
		}
	}

	li + li {
		margin-left: 4.1rem;

		@include breakpoint-down(tablet) {
			margin-left: 0;
			margin-top: 2rem; 
		}
	}

	a {
		display: inline-block;
		text-decoration: underline;
		text-decoration-color: transparent;
		transition: text-decoration-color $t-duration, color $t-duration;
		text-underline-offset: 0.9rem;

		&:hover{
			color: black;
		}
	}

	li.is-active a {
		text-decoration: underline;
		color: $c-yellow; 
		text-decoration-color: currentColor;
	}

	li:hover a {
		text-decoration: underline;
		text-anchor: underline;
		text-decoration-color: currentColor;

	}
}
