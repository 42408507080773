/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
	font-size: 1.4rem;
	font-weight: 600; 
	line-height: 1.43; 
	padding: 1.6rem 5.6rem; 
	background-color: $c-yellow; 
	color: $c-white; 
	transition: color $t-duration, background-color $t-duration;

	@include breakpoint-down(mobile) {
		font-size: 1.5rem;
		line-height: 1.07;
		padding: 1.9rem 5.6rem;
	}

	&:disabled{
		opacity: 0.4;
	}

	&:hover {
		background-color: $c-white; 
		color: $c-black-off;
	}
}

.btn--white {
	background-color: $c-white;
	color: $c-black-off;

	&:hover {
		background-color: $c-gray-2;
	}
}

.btn--red{
	background-color: $c-darkred !important;
	color: white !important;
	border-color: transparent !important;

	&:hover{

	}
}
/*  Btn block  */

.btn--block {
	display: block;
	padding-left: 0;
	padding-right: 0;
}
