/* ------------------------------------------------------------ *\
	Section Members
\* ------------------------------------------------------------ */

.section-members {
	padding: 4rem 0 16.1rem;

	@include breakpoint-down(mobile) {
		padding: 6.4rem 0 9.5rem;
	}

	.section__head {
		max-width: 47%;
		padding-left: .3rem;
		font-weight: 700; 
		line-height: 1; 
		margin-bottom: 8.6rem;

		@include breakpoint-down(tablet) {
			max-width: 54%;
		}

		@include breakpoint-down(mobile) {
			max-width: unset;
			font-size: 1.1rem;
			line-height: 1.45;
			font-weight: 700;
			margin-bottom: 4rem;
		}
	}

	.section__head h2 {
		margin-bottom: 2.8rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 5.4rem;
			line-height: 1.28;
			margin-bottom: .6rem;
		}
	}

	.section__head p {
		letter-spacing: 0.062em;

		@include breakpoint-down(mobile) {
			letter-spacing: 0.09em;
			margin-left: .2rem;
		}
	}
}