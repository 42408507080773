//Variables
$font-size-base             : 1.6rem;
$font-size-mobile           : 1.4rem;
$font-weight-base           : 500;
$line-height-base           : 2;
$line-height-mobile         : 2;

$ff-plus-jakarta 			:'Plus Jakarta Sans', Arial, sans-serif; 
$ff-garamond 				:'EB Garamond', Georgia, serif; 

$t-duration                 : 0.4s;

$shell-max-width            : 124rem;
$shell-gutter               : 1.5rem;
$shell-gutter-mobile 		: 1.6rem;

$c-black                    : #000;
$c-white                    : #fff;
$c-base                     : #055590;

$c-black-off: #181818;
$c-darkgreen: #112f07;
$c-yellow: #AA8B3F;
$c-beige: #F4F4F4;
$c-gray: #DADADA;
$c-gray-2: #CDCDCD;
$c-gray-3: #B4B4B4;
$c-gray-4: #8D8989;
$c-gray-5: #C4C4C4;
$c-gray-6: #DCDCDC;
$c-darkred: #6C1310;

$responsive-ios             : 812px;

$breakpoints: (
	mobile-small: 374px,
	mobile-medium: 575px,
	mobile: 767px,
	admin-bar: 782px,
	tablet: 1023px,
	small-desktop: 1199px,
	large-desktop: 1399px
);
