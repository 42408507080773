/* ------------------------------------------------------------ *\
	Section Callout
\* ------------------------------------------------------------ */

.section-callout {
	padding: 11.7rem 0 18.8rem;

	@include breakpoint-down(mobile) {
		padding: 0 0 12rem;
	}

	.section__inner {
		background-color: $c-beige; 
		padding: 5.4rem 10.6rem 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include breakpoint-down(small-desktop) {
			padding: 5.4rem 3rem 0;
		}

		@include breakpoint-down(mobile) {
			margin: 0 -#{$shell-gutter-mobile};
			padding: 3.4rem $shell-gutter-mobile 0;
		}
	}

	.section__content {
		flex: 0 0 39%;
		max-width: 39%;
		padding-top: 9%;

		@include breakpoint-down(small-desktop) {
			padding-bottom: 2rem;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-bottom: 2.6rem;
		}
	}

	.section__content h3 {
		margin-bottom: 3.6rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			margin-bottom: 1.7rem;
		}
	}

	.section__content p {
		margin-bottom: 0;
		color: $c-gray-4;
	}

	.section__content p + .btn {
		margin-top: 2.5rem;

		@include breakpoint-down(mobile) {
			margin-top: 3.7rem;
		}
	}

	.section__content .btn {
		@include breakpoint-down(mobile) {
			max-width: 100%;
			width: 100%;
		}
	}

	.section__content p:not(:last-child) {
		margin-bottom: 2rem;
	}

	.section__image {
		flex: 0 0 48.5%;
		max-width: 48.5%;
		margin-bottom: -7rem;

		@include breakpoint-down(mobile) {
			flex: 0 0 80.5%;
			max-width: 80.5%;
			margin-left: auto;
			position: relative;
			padding-bottom: 105.5%;
			margin-bottom: -4.6rem;
		}
	}

	.section__image img {
		width: 100%; 
		height: auto; 

		@include breakpoint-down(mobile) {
			position: absolute;
			top: 0; 
			left: 0; 
			width: 100%; 
			height: 100%; 
			object-fit: cover;
		}
	}
}

.section-callout--alt {
	padding: 6.2rem 0 2rem;

	@include breakpoint-down(mobile) {
		padding: 0 0 4.1rem;
	}

	.section__inner {
		padding: 5rem 0;

		@include breakpoint-down(mobile) {
			padding: 9rem $shell-gutter-mobile 0;
		}
	}

	.section__content {
		flex: 0 0 45.7%;
		max-width: 45.7%;
		padding-left: 11.2%;
		padding-top: 16.8%;

		@include breakpoint-down(tablet) {
			padding-left: 3rem;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 2rem 0 0; 
			line-height: 2.29;
			position: relative;
			z-index: 2;
		}
	}

	.section__content h3 {
		margin-bottom: 2.6rem;

		@include breakpoint-down(mobile) {
			font-size: 5.2rem;
			font-weight: 700; 
			line-height: 1.2;
			margin-bottom: 1.5rem;
		}
	}

	.section__image {
		flex: 0 0 36.3%;
		max-width: 36.3%;
		margin-right: 2.5%; 
		margin-bottom: 0;

		@include breakpoint-down(mobile) {
			flex: 0 0 83.385%;
			max-width: 83.385%;
			margin-top: -3.7rem;
			margin-right: -6.5rem;
			position: relative;
			padding-bottom: 0;
		}
	}

	.section__image img {
		@include breakpoint-down(mobile) {
			object-fit: initial;
			height: auto;
			position: static;
			width: 100%;
		}
	}

	.section__foot {
		position: relative;
		z-index: 2; 
		margin-top: 5rem;
		background-color: $c-darkred; 
		color: $c-white; 
		text-align: center;
		padding: 4.7rem 0;
		font-size: 1.7rem;
		line-height: 1.2;

		@include breakpoint-down(mobile) {
			margin-top: -5.3rem;
			padding: 4rem 0;
			font-size: 1.3rem;
			line-height: 1.23;
			font-weight: 700;
		}
	}

	.section__foot p {
		letter-spacing: 0.08em;
		margin-bottom: 3.4rem;

		@include breakpoint-down(mobile) {
			margin: 0 auto 1.8rem;
			max-width: 20rem;

		}
	}

	.section__foot .btn {
		padding: 1.6rem 5.9rem;

		@include breakpoint-down(mobile) {
			padding: 1.9rem 5.8rem;
			font-size: 1.4rem;
			font-weight: 600;
		}
	}
}