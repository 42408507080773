/* ------------------------------------------------------------ *\
	Accordion Small
\* ------------------------------------------------------------ */

.accordion-small {
	.accordion__section + .accordion__section {
		border-top: .1rem solid currentColor; 
	}

	.accordion__head h6 {
		font-size: 1.6rem;
		line-height: 2; 
		font-weight: 500; 
	}

	.accordion__head h6 a {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 2.6rem .4rem 2.7rem 0;
		text-decoration: none;
		transition: color $t-duration;
		position: relative;

		@include breakpoint-down(mobile) {
			padding: 2.5rem .4rem 1.4rem 0;
		}
	}

	.accordion__head h6:hover a {
		color: $c-yellow;
	}


	.accordion__head a span {
		padding-right: 3rem;

		@include breakpoint-down(mobile) {
			width: 100%;
			padding-right: 5.1rem;
		}
	}

	.accordion__section.is-current .accordion__head a i {
		transform: rotateZ(405deg);
	}

	.accordion__head a i {
		position: absolute;
		top: 3.5rem; 
		right: .4rem;
		transition: transform $t-duration;
		font-size: 0;

		@include breakpoint-down(mobile) {
			top: 50%;
			transform: translateY(-50%);
			margin-top: 0.6rem;
		}
	}
	.accordion__head a i svg {
		@include breakpoint-down(mobile) {
			width: 2rem; 
			height: auto;
		}
	}

	.accordion__body {
		font-size: 1.4rem;
		line-height: 2;
		padding: .2rem 3rem 2.8rem 2.2rem; 
		max-width: 43.8rem;
		display: none;

		@include breakpoint-down(mobile) {
			padding: 1.1rem 8rem 1.1rem 0;
		}
	}
}