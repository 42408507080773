/* ------------------------------------------------------------ *\
	Tab
\* ------------------------------------------------------------ */

.tab {
	.tab__inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;	
	}

	.tab__aside {
		flex: 0 0 31.7%;
		max-width: 31.7%;
		padding-top: 4.4rem;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-top: 3.3rem;
		}
	}

	.tab__content {
		margin-left: 2.3%;
		flex: 1 0 66%;
		margin-right: calc((100vw - 100%) / -2);

		@include breakpoint-down(mobile) {
			margin-left: 0;
			padding-top: 4.4rem;
			margin-right: -#{$shell-gutter-mobile};
		}
	}
}