/* ------------------------------------------------------------ *\
	Copy
\* ------------------------------------------------------------ */

.copy {
	font-size: 1.3rem;
	line-height: 2; 

	@include breakpoint-down(mobile) {
		font-size: 1.1rem;
	}
}