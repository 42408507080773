/* ------------------------------------------------------------ *\
	Section Testimonials
\* ------------------------------------------------------------ */

.section-testimonials {
	background-color: $c-beige;
	padding: 15.6rem 0; 
	overflow: hidden;

	@include breakpoint-down(tablet) {
		padding: 7rem 0 9.5rem;
	}
	
	.section__head {
		margin: 0 0 12.4rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 4rem;
		}
	}

	.section__head h2 {
		font-size: 9rem;
		line-height: 1;
		font-weight: bold;

		@include breakpoint-down(tablet) {
			font-size: 6.3rem;
		}

		@include breakpoint-down(mobile) {
			font-size: 4rem;
			line-height: 1.15;
		}
	}
}

.section-testimonials--transparent {
	padding: 16rem 0 14.6rem; 
	position: relative;
	color: $c-white; 
	background-color: transparent; 

	@include breakpoint-down(mobile) {
		padding: 7.4rem 0 9.8rem;
	}

	.section__head {
		margin-bottom: 12.7rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 4.2rem;
		}
	}

	.section__head h2 {
		font-size: 8.6rem;

		@include breakpoint-down(tablet) {
			font-size: 6rem;
		}

		@include breakpoint-down(mobile) {
			font-size: 5.2rem;
			line-height: 1.2;
			font-weight: 700; 
			letter-spacing: 0.02em;
		}
	}

	.section__body {
		@include breakpoint-down(mobile) {
			padding-left: 0.4rem;
		}
	}

	.slider--default :where(.slider__paging) {
		right: 10.3rem; 

		@include breakpoint-down(tablet) {
			right: 3rem;
		}
	}

	.slider--default :where(.slider__prev), 
	.slider--default :where(.slider__next) {
		right: 10.3rem;

		@include breakpoint-down(tablet) {
			right: 3rem;
		}
	}
}

.section-testimonials--transparent-small {
	@include breakpoint-down(mobile) {
		padding: 7rem 0 9.8rem;
	}

	.section__head h2 {
		@include breakpoint-down(mobile) {
			font-size: 4rem;
			line-height: 1.15;
			letter-spacing: 0;
		}
	}

	.section__body {
		@include breakpoint-down(mobile) {
			padding-left: .1rem;
		}
	}
}