/* ------------------------------------------------------------ *\
	Fields
\* ------------------------------------------------------------ */

.field {
	max-width: 100%;
	width: 100%;
	border: 0;
	border-bottom: .1rem solid $c-black-off; 
	padding: .8rem 0 .7rem;
	border-radius: 0;
	background-color: transparent; 
	font-size: 1.6rem;
	line-height: 2; 
	color: $c-black;


	@include breakpoint-down(mobile) {
		padding: .7rem 0 .8rem;
	}
}

/*  Field textarea  */
.field--textarea {
resize: none;
}
