/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

[class*="ico-"] {
	font-size: 0;
	display: inline-block;

	img,
	svg {
		height: auto;
	}
}

.ico-arrow-down {
	svg {
		width: 1.2rem; 
	}
}

.ico-arrow-right {
	svg {
		width: 1.6rem;
	}
}

.ico-instagram {
	svg {
		width: 2.2rem;

		@include breakpoint-down(mobile) {
			width: 2.8rem;
		}
	}
}

.ico-facebook {
	svg {
		width: 1rem;

		@include breakpoint-down(mobile) {
			width: 1.2rem;
		}
	}
}