/* ------------------------------------------------------------ *\
	Section Asymetric
\* ------------------------------------------------------------ */

.section-asymetric {
	padding: 22.5rem 0 21.6rem;
	background-color: $c-beige; 

	@include breakpoint-down(mobile) {
		padding: 7.1rem 0 7rem;
	}

	.section__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.section__image {
		flex: 0 0 31.7%;
		max-width: 31.7%;
		margin-bottom: .9rem;
	}

	.section__inner > .section__image {
		@include breakpoint-down(mobile) {
			display: none;
		}
	}

	.section__image > img {
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}

	.section__content {
		flex: 0 0 57.6%;
		max-width: 57.6%;

		@include breakpoint-down(tablet) {
			flex: 0 0 63%;
			max-width: 63%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%; 
			max-width: 100%;
			
		}
	}

	.section__content .section__image {
		max-width: unset;
		position: relative;
		margin-bottom: 8.1rem;

		@include breakpoint-down(mobile) {
			margin-left: -#{$shell-gutter-mobile};
			margin-bottom: 4rem;
		}
	}

	.section__content .section__image > img {
		object-fit: none;
		width: 100%; 
		height: auto;
		position: relative;
	}

	.section__stamp {
		position: absolute;
		top: 0; 
		margin-top: -.8rem;
		right: 2rem; 
		transform: translateY(-50%);
		width: 14.6rem;

		@include breakpoint-down(mobile) {
			width: 13rem;
			height: 13rem;
			top: 50%;
			right: 0;
			transform: translate(50%, -50%);
			margin-top: 0.1rem;
			margin-right: -.6rem; 
		}
	}

	.section__stamp :where(img, svg) {
		width: 100%; 
		height: auto;
		fill: $c-yellow;
	}

	.section__content h2 {
		max-width: 95%;
		margin-bottom: 8.6rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 5.4rem;
			line-height: 1.28;
			margin-bottom: 3.9rem;
		}
	}

	.section__bar {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding-left: .3rem;
		color: $c-gray-4; 

		@include breakpoint-down(mobile) {
			display: block;
			font-size: 1.4rem;
			line-height: 2.29;
		}
	}

	.section__bar p {
		margin-bottom: 0;
	}

	.section__bar .btn {
		flex: 0 0 auto;
		margin-left: 7rem;
		margin-top: .1rem;

		@include breakpoint-down(tablet) {
			margin-left: 2rem;
		}

		@include breakpoint-down(mobile) {
			width: 100%; 
			max-width: 100%;
			margin-top: 3rem;
			margin-left: 0;
			font-size: 1.4rem;
			line-height: 1.43;
			padding: 1.6rem 5.6rem;
			font-weight: 600; 
		}
	}
}