/* ------------------------------------------------------------ *\
	Member
\* ------------------------------------------------------------ */

.member {
	.member__image {
		position: relative;
		padding-bottom: 83.8%;
		margin-bottom: 2.3rem;

		@include breakpoint-down(mobile) {
			padding-bottom: 93.5%;
			margin-bottom: 2.6rem;
		}
	}

	.member__image img {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}

	.member__content {
		position: relative;
	}

	.member__content h6 {
		text-transform: uppercase;
		letter-spacing: 0.08em;
		padding-top: .4rem;
		margin-bottom: 2.5rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			margin-bottom: .5rem;
		}
	}

	.member__content h4 {
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 2.8rem;
			line-height: 2; 
		}
	}

	.member__icon {
		position: absolute;
		top: 0; 
		right: .2rem; 
		width: 2.5rem;
		font-size: 0;

		@include breakpoint-down(mobile) {
			display: none;
		}
	}

	.member__icon img, svg {
		width: 100%; 
		height: auto;
	}
}