/* ------------------------------------------------------------ *\
	Ornament
\* ------------------------------------------------------------ */

.ornament {
	color: $c-yellow; 
	text-transform: uppercase;
	font-weight: 700; 
	line-height: 1.23; 
	font-size: 1.3rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	max-width: 50rem;
	letter-spacing: 0.07em;

	&:before {
		content: "";
		height: .2rem; 
		flex: 0 1 100%;
		background-color: $c-yellow; 
	}

	span {
		flex-shrink: 0;
		margin-left: 4.8rem;

		@include breakpoint-down(mobile) {
			margin-left: 3.9rem;
		}
	}

	h5 {
		text-transform: none;
		flex-shrink: 0;
		margin-left: .8rem;
		font-size: 2.6rem;
		line-height: 1.23;
		font-family: $ff-garamond; 
		font-weight: 500;
		letter-spacing: 0;
	}
}

.ornament--small {
	&:before {
		height: .1rem; 
	}
}