/* ------------------------------------------------------------ *\
	Cart
\* ------------------------------------------------------------ */

.cart {
	.cart__trigger {
		display: flex;
		width: 4rem; 
		height: 4rem; 
		border-radius: 50%;
		border: .1rem solid currentColor; 
		justify-content: center;
		align-items: center;
		position: relative;
		transition: border-color $t-duration;
		color: white;

		svg{
			transition: color $t-duration;
		}

		&:hover{
			border-color: black;

			svg{
				color: black;
			}

			span{
				color: white;
			}
		}

		span {
			position: absolute;
			top: 0;
			right: 0;
			width: 1.8rem;
			height: 1.8rem;
			border-radius: 50%;
			border: .2rem solid;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $c-yellow;
			transform: translate(42%, -27%);
			font-size: .8rem;
			line-height: 1;
			font-weight: 700;
			color: white !important;
		}
	}
}

.header--home, .header--our-story, .header--contact, .header--privacy-policy{
	.cart__trigger > span{
		border-color: $c-darkgreen;
	}
}

.header--careers, .header--faq{
	.cart__trigger > span{
		border-color: $c-white;
	}
}