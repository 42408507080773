/* ------------------------------------------------------------ *\
	List Info
\* ------------------------------------------------------------ */

.list-info {
	list-style: none; 

	li + li {
		margin-top: 1rem;

		@include breakpoint-down(mobile) {
			margin-top: .4rem;
		}
	}

	address {
		font-size: 1.4rem; 
		line-height: 2;
		font-weight: 500;
	}

	a {
		font-size: 1.3rem;
		letter-spacing: 0.04em;
	}
}