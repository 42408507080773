/* ------------------------------------------------------------ *\
	List Props
\* ------------------------------------------------------------ */

.list-props {
	list-style: none;
	display: flex;
	items: center;
	flex-wrap: wrap;

	@include breakpoint-down(mobile) {
		margin-bottom: -1.5rem;
	}

	li {
		flex: 0 0 max-content;

		@include breakpoint-down(mobile) {
			margin-bottom: 1.5rem;
		}
	}

	li:not(:last-child) {
		padding-right: 2.3rem;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			top: 50%;
			transform: translateY(-50%); 
			right: 1rem;
			width: .3rem;
			height: .3rem;
			background-color: currentColor; 
		}
	}
}