/* ------------------------------------------------------------ *\
	Background Wrapper
\* ------------------------------------------------------------ */

.background-wrapper {
	background-color: $c-darkred; 
	position: relative;

	.background__pattern {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;
	}

	.background__pattern img {
		width: 100%;
		height: 100%; 
		object-fit: cover;
		mix-blend-mode: multiply;
	}
}