/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 7.6rem; 
	height: 7.6rem; 
	border-radius: 50%;
	background-color: $c-yellow;
	font-size: 0;

	@include breakpoint-down(mobile) {
		width: 7.2rem;
		height: 7.2rem;
	}

	svg {
		width: 5rem;

		@include breakpoint-down(mobile) {
			width: 4.2rem;
		}
	}
}
