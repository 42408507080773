/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */

.shell {
	max-width: calc( #{$shell-max-width} + #{$shell-gutter} * 2 );
	padding-left: $shell-gutter;
	padding-right: $shell-gutter;
	margin: auto;
	width: 100%;

	@include breakpoint-down(mobile) {
		max-width: calc( #{$shell-max-width} + #{$shell-gutter-mobile} * 2 );
		padding-left: $shell-gutter-mobile;
		padding-right: $shell-gutter-mobile;
	}
}

/* Shell Fluid */

.shell--fluid {
	max-width: none;
}
