/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	background-color: $c-black-off;
	color: $c-gray-4;  
	padding: 18rem 0 9.9rem;
	position: relative;

	@include breakpoint-down(tablet) {
		padding: 10rem 0 10rem;
	}

	@include breakpoint-down(mobile) {
		padding: 6.9rem 0 10rem;
	}

	.footer__shape {
		position: absolute;
		bottom: 0; 
		left: 50%;
		width: 57.35%;
		transform: translateX(-50%);

		@include breakpoint-down(mobile) {
			width: 50.8rem;
			bottom: -10.7rem; 
		}
	}

	.shell {
		position: relative;
	}

	.footer__cols {
		display: flex;
		flex-wrap: wrap;

		@include breakpoint-down(tablet) {
			margin-bottom: -4rem;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: -5rem;
		}
	}

	.footer__col {
		flex: 0 0 50%;
		max-width: 50%;

		@include breakpoint-down(tablet) {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 0 4rem;
		}

		@include breakpoint-down(mobile) {
			padding-bottom: 5rem;
		}
	}

	.footer__col--small {
		flex: 0 0 25%;
		max-width: 25%;

		@include breakpoint-down(tablet) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.footer__col--large {
		flex: 0 0 50%;
		max-width: 50%;

		@include breakpoint-down(tablet) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.logo {
		width: 12.1rem; 
		height: 12.1rem; 
		margin-top: 1rem;
		margin-left: -.2rem;
		margin-bottom: 2.7rem;

		@include breakpoint-down(tablet) {
			margin: 0;
		}

		@include breakpoint-down(mobile) {
			width: 8.8rem;
			height: 8.8rem;
			margin-bottom: 1.9rem;
		}
	}

	.logo svg {
		width: 8rem;
		height: auto;

		@include breakpoint-down(mobile) {
			width: 3.8rem; 
		}
	}

	.copy {
		max-width: 13.9rem;
		color: $c-gray-4;
	}

	.footer__inner {
		display: flex;
		max-width: 63.3rem;

		@include breakpoint-down(tablet) {
			max-width: unset;
		}
	}

	.footer__cols h6 {
		font-size: 1.6rem;
		line-height: 2; 
		color: $c-white;
		font-weight: 500; 

		@include breakpoint-down(mobile) {
			font-size: 1.4rem;
			line-height: 1.29;
		}
	}

	.footer__nav h6 {
		margin-bottom: 2.8rem;
	}

	.footer__nav ul {
		list-style: none;
		line-height: 1.22;
		color: $c-gray-4;
		padding-left: 0;

		@include breakpoint-down(mobile) {
			font-size: 1.3rem;
		}
	}

	.footer__nav li + li {
		margin-top: 2.6rem;

		@include breakpoint-down(mobile) {
			margin-top: 2rem;
		}
	}

	.footer__nav a {
		text-decoration: underline;
		text-decoration-color: transparent;
		transition: text-decoration-color $t-duration;
		text-underline-offset: .3rem;
	}

	.footer__nav li:hover a {
		text-decoration: underline;
		text-decoration-color: currentColor;
	}

	.footer__inner .footer__nav {
		flex: 0 0 calc(100% / 3);
		max-width: calc(100% / 3);
	}

	.footer__inner .footer__nav + .footer__nav {
		&:last-child {
			@include breakpoint-down(mobile) {
				margin-left: 0.7rem;
			}
		}

		@include breakpoint-down(mobile) {
			margin-left: 1.7rem;
		}
	}

	.footer__aside {
		max-width: 28.7rem;
		margin-left: 2.3rem;

		@include breakpoint-down(tablet) {
			margin-left: 0;
			max-width: unset;
		}
	}

	.footer__aside h6 {
		margin-bottom: 2rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 4.2rem;
		}
	} 

	.footer__body {
		margin: 7.8rem auto 0;
		max-width: 50%;
		font-size: 1rem;
		line-height: 1.5;

		@include breakpoint-down(tablet) {
			max-width: unset;
			text-align: center;
		}

		@include breakpoint-down(mobile) {
			margin-top: 4.1rem;
			margin: 4.1rem -.6rem 0; 
			font-size: 1.1rem;
			line-height: 1.36;
		}
	}

	.footer__body p {
		margin-bottom: 0;

		@include breakpoint-down(tablet) {
			margin-bottom: 4rem;
		}
	}

	.footer__body .copy {
		@include breakpoint-down(tablet) {
			max-width: unset;
		}
	}

	.footer__icon {
		position: absolute;
		width: 6rem; 
		height: 6rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: $c-yellow; 
		color: $c-white;
		bottom: 0; 
		right: 0;
		transform: translate(50%, 50%);
		margin-right: -1rem;
		margin-bottom: .5rem;
		cursor: pointer;

		&:hover{

		}

		@include breakpoint-down(large-desktop) {
			margin-right: 0;
			right: $shell-gutter;
			transform: translate(0, 50%);
		}

		@include breakpoint-down(mobile) {
			width: 4.4rem; 
			height: 4.4rem;
			margin-bottom: -4.9rem;
		}
	}

	.footer__icon svg {
		@include breakpoint-down(mobile) {
			width: 1.5rem; 
			height: auto;
		}
	}
}
