/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */

.checkbox__modal {
  display: flex;
  padding: 0 88px;

  > input {
    display: none;

    &:checked{
      ~span:after{
        content: "";
        position: absolute;
        display: block;
        left: 5px;
        top: 2px;
        width: 6px;
        height: 11px;
        border: solid #6C1310;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }

  > span{
    background: transparent;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid white;
    margin-right: 10px;
    margin-top: 8px;
    position: relative;
  }

  > div{
    font-size: 16px;
    line-height: 29px;
    color: white;
  }
}
