/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */

.product {
	background-color: $c-beige;
	position: relative; 
	padding: 4.6rem 4.3rem; 

	@include breakpoint-down(mobile) {
		padding: 3rem 2.9rem 1.9rem; 
	}

	.product__head {
		position: absolute;
		top: 0; 
		right: 10.5rem;
		transform: rotateZ(-90deg);
		transform-origin: bottom right;
		color: $c-yellow; 
		border-bottom: .2rem solid $c-yellow;
		margin-top: -7.7rem;
		padding-right: 3rem;

		@include breakpoint-down(small-desktop) {
			transform: translateY(-50%) rotateZ(-90deg);
		}

		@include breakpoint-down(tablet) {
			transform: translateY(0) rotateZ(-90deg);
			right: 4rem;
		}

		@include breakpoint-down(mobile) {
			margin-top: -.5rem;
			right: 1.7rem;
			transform: translateY(-50%);
			border-width: .1rem;
			padding-right: .3rem;
		}
	}

	.product__head h1 {
		font-family: $ff-garamond; 
		font-weight: 500;
		letter-spacing: 0.002em; 

		@include breakpoint-down(mobile) {
			font-size: 3.6rem;
			line-height: 1.25; 
		}
	}

	.product__image {
		max-width: 74.2%;
		position: relative;
		padding-bottom: 69.45%;
		margin-left: -4.3rem;
		margin-bottom: 3.2rem;
		mix-blend-mode: multiply;

		@include breakpoint-down(mobile) {
			margin-left: 0;
			max-width: 94.02%;
			padding-bottom: 87.71%;
			margin-bottom: 2.2rem;
		}
	}

	.product__image img {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}

	.product__content {
		@include breakpoint-down(mobile) {
			padding: 0 .6rem;
		}
	}
	
	.product__content h6 {
		text-transform: uppercase;
		letter-spacing: 0.08em;
		max-width: 10.1rem;
	}
	.product__content h6 a {
		text-decoration: underline;
		text-decoration-color: transparent;
		transition: text-decoration-color $t-duration;

		&:hover {
			text-decoration-color: currentColor;
		}
	}
}