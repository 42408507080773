/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */

.tabs {
	.tabs__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.4rem;
		max-width: 91.9%;

		@include breakpoint-down(mobile) {
			max-width: unset;
			margin-left: 0;
			padding: 0 1.6rem 0 1.8rem;
			font-size: 1.6rem;
		}
	}

	.tabs__nav {
		color: $c-gray-4;

		@include breakpoint-down(mobile) {
			width: 100%;
		}
	}

	.tabs__nav ul {
		list-style: none;
		display: flex;
		align-items: center;
		padding-left: 0;

		@include breakpoint-down(mobile) {
			justify-content: space-between;
		}
	}

	.tabs__head .btn {
		@include breakpoint-down(tablet) {
			padding: 1.6rem 4.6rem;
		}
	}

	.tabs__nav li + li {
		margin-left: 4rem;

		@include breakpoint-down(tablet) {
			margin-left: 2rem;
		}

		@include breakpoint-down(mobile) {
			margin-left: 0;
		}
	}

	.tabs__nav a {
		display: inline-block;
		text-decoration: underline;
		text-decoration-color: transparent;
		text-underline-offset: 0.7rem;
		transition: text-decoration-color $t-duration, color $t-duration;
	}

	.tabs__nav li:hover a {
		text-decoration-color: currentColor;
	}

	.tabs__nav li.is-current a {
		color: $c-yellow; 
		text-decoration-color: currentColor;
	}

	.tabs__body {
		position: relative;
	}

	.tabs__body .tab {
		position: absolute;
		opacity: 0;
		pointer-events: none;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;
		transform: scale(0.9);
		transition: transform $t-duration, opacity $t-duration;
	}

	.tabs__body .tab.is-current {
		position: static;
		opacity: 1;
		transform: scale(1.0);
		pointer-events: all;
	}

	.tabs__actions {
		padding-top: 4rem;
	}

	.tabs__actions .btn {
		@include breakpoint-down(mobile) {
			width: 100%;
		}

		&:hover {
			background-color: $c-gray-4;
		}
	}
}

.tabs--alt {
	.tabs__head {
		text-transform: uppercase;
		font-size: 1.3rem;
		line-height: 1.23; 
		color: $c-gray-5;
		margin-left: 0;
		margin-bottom: 6.6rem;

		@include breakpoint-down(mobile) {
			font-size: 1.1rem;
			line-height: 1.23;
			padding: 0;
			margin-bottom: 1.8rem;
		}
	}

	.tabs__nav {
		font-weight: 700;
		letter-spacing: 0.08em;
	}

	.tabs__nav ul {
		@include breakpoint-down(mobile) {
			justify-content: flex-start;
		}
	}

	.tabs__nav li + li {
		margin-left: 3.8rem;

		@include breakpoint-down(mobile) {
			margin-left: 3rem;
		}
	}

	.tabs__nav li.is-current a {
		color: $c-yellow;
		text-decoration-color: currentColor;
	}

	.tabs__nav a {
		text-decoration-thickness: .1rem;
		text-underline-offset: 0.1rem;
	}
}