/* ------------------------------------------------------------ *\
	Section Contact
\* ------------------------------------------------------------ */

.section-contact {
	padding: 12rem 0 13.7rem;
	background-color: $c-beige; 

	@include breakpoint-down(mobile) {
		padding: 5.4rem 0 6.5rem;
	}

	.section__inner {
		max-width: 102.8rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.section__aside {
		flex: 0 0 37%;
		max-width: 37%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			font-size: 1.6rem;
			line-height: 2; 
		}
	}

	.section__aside h3 {
		margin-bottom: 0;
		padding-bottom: 4.8rem;
		font-weight: bold;

		@include breakpoint-down(mobile) {
			font-size: 5.2rem; 
			padding-bottom: 1.8rem;
		}
	}

	.section__aside p {
		margin-bottom: 0;
		padding-bottom: 1.6rem;
		color: $c-gray-4;

		@include breakpoint-down(mobile) {
			padding-bottom: .5rem;
		}
	}

	.section__aside p small {
		color: $c-black;
		letter-spacing: 0.025em;
	}

	.section__aside a {
		text-decoration: underline;
		text-decoration-color: transparent;
		transition: text-decoration-color $t-duration;

		&:hover {
			text-decoration-color: currentColor;
		}
	}

	.section__content {
		flex: 0 0 48.5%;
		max-width: 48.5%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-top: 2.5rem; 
		}
	}
}