/* ------------------------------------------------------------ *\
	Section Plain
\* ------------------------------------------------------------ */

.section-plain {
	padding: 13.9rem 0 13.3rem;

	@include breakpoint-down(mobile) {
		padding: 14.7rem 0 1.7rem;
	}
}